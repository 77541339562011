import React from 'react'

import type { PageProps } from '~/pages'
import StoryCard from './StoryCard'

const LatestBlogSection = ({ data }: { data: PageProps }) => {
  const categoryData = data.categoryTree
  const storyList = data.storiesData

  return (
    <div className='w-full bg-[#edf2f7] py-20'>
      <h2 className='blog-container text-secondary text-center text-2xl font-bold'>
        Some of our latest stories
      </h2>
      <div className='blog-container mt-7 flex flex-col'>
        {storyList.map((story, storyIndex) => {
          return (
            <StoryCard
              key={storyIndex}
              storyData={story}
              categories={categoryData}
              innerMostCategory={data?.innermostParents.find(
                (item) => item.storyId === story.id,
              )}
            />
          )
        })}
      </div>
    </div>
  )
}

export default LatestBlogSection
