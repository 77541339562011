import React from 'react'
import Link from 'next/link'

import type {
  CategoryWithChildren,
  CategoryWithParentCategory,
  StoryWithKeyIndicatorsAndCategories,
} from '@aqua/db/types'

import { useStory } from '~/hooks/story/useStory'
import StoryCardBadges from '../story-card-components/StoryCardBadges'
import StoryCardCountryAndYear from '../story-card-components/StoryCardCountryAndYear'
import StoryCardStats from '../story-card-components/StoryCardStats'
import StoryCardStatsMobile from '../story-card-components/StoryCardStatsMobile'
import StoryCardSummary from '../story-card-components/StoryCardSummary'

interface StoryCardPropType {
  storyData: StoryWithKeyIndicatorsAndCategories
  innerMostCategory?: {
    storyId: number
    type: CategoryWithParentCategory | null
  }
  indicators?: CategoryWithChildren
  categories: CategoryWithChildren[]
}

const StoryCard = ({
  storyData,
  innerMostCategory,
  categories,
}: StoryCardPropType) => {
  const {
    change,
    sign,
    indicator,
    country,
    highlightedCategory,
    indicatorIcon,
    yearRange: { startYear, endYear },
  } = useStory(storyData, categories)
  const variable = indicator?.variableName

  return (
    <Link href={`/story/${storyData.externalId}`}>
      <div className='hover:border-primary mb-2 flex w-full cursor-pointer rounded bg-white hover:border-2'>
        <StoryCardStats
          innerMostCategory={innerMostCategory}
          sign={sign}
          storyImage={indicatorIcon}
          change={change}
          variable={variable}
          indicator={indicator}
        />
        <div className='flex w-full flex-col '>
          <StoryCardStatsMobile
            innerMostCategory={innerMostCategory}
            storyImage={indicatorIcon}
            sign={sign}
            change={change}
            variable={variable}
            indicator={indicator}
            startYear={startYear}
            endYear={endYear}
            country={country}
          />
          <div className='flex w-full px-6 py-5'>
            <StoryCardSummary summary={storyData.summary} />
            <StoryCardCountryAndYear
              country={country}
              startYear={startYear}
              endYear={endYear}
            />
          </div>
          <StoryCardBadges
            innerMostCategory={innerMostCategory}
            storyChild={highlightedCategory}
            storyData={storyData}
          />
        </div>
      </div>
    </Link>
  )
}

export default StoryCard
