import Image from 'next/image'
import Link from 'next/link'
import { Button } from '@/components/ui/button'

import * as heroIcon2 from '../../../public/svg/image2.svg'
import * as heroIcon from '../../../public/svg/intro.svg'

const HeroSection = () => {
  return (
    <>
      <div className='bg-primary w-full'>
        <div className='md:outer-container-md lg:outer-container h-full w-full py-20'>
          <div className='flex h-full w-full flex-col items-center justify-center gap-x-10 gap-y-20 md:flex-row'>
            <h3 className='outer-container-sm  pr-9 text-left  text-[18px] font-semibold leading-7  text-white md:max-w-[556px] md:p-0'>
              Aquadata is a database of stories demonstrating the importance of
              aquatic food systems worldwide.
            </h3>
            <Image
              src={heroIcon}
              alt="A person's hand stirs a traditional dish in a large black cooking pot. The dish features small, silvery fish submerged in a yellowish broth with green herbs and spices visible. The focus is on the glistening surface of the simmering stew, capturing the motion of cooking."
              className=' h-auto w-full md:max-w-[400px]'
              priority
            />
          </div>
        </div>
      </div>
      <div className='bg-secondary w-full'>
        <div className='md:outer-container-md lg:outer-container h-full w-full py-20'>
          <div className='flex h-full w-full flex-col items-center justify-center gap-x-9 gap-y-20 md:flex-row-reverse lg:gap-x-28'>
            <div className='outer-container-sm flex flex-col gap-y-6 md:p-0'>
              <h1 className=' text-left text-3xl font-bold leading-9 text-white md:p-0'>
                Read the stories. Get the data.
              </h1>
              <p className='text-[16px] font-normal leading-6 text-white'>
                Learn about how aquatic food systems are contributing to
                nutrition, sustainability, gender equality and more.
              </p>
              <Link href='/search'>
                <Button className='h-fit w-fit'>Search stories</Button>
              </Link>
            </div>
            <Image
              src={heroIcon2}
              alt='A young woman with a red bindi on her forehead is looking directly at the camera. She is wearing a blue traditional outfit with intricate patterns. In the background, several people are seated on the ground, sorting through a large assortment of small fish spread out on a sheet. A white bucket partially filled with fish is in the foreground.'
              className=' h-auto w-full lg:w-auto'
              priority
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
