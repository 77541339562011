import React, { useState } from 'react'
import Image from 'next/image'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from '@/components/ui/use-toast'

import { trpc } from '~/utils/api'
import worldPicture from '../../../public/svg/world.png'

const NewsLetterSignup = () => {
  const [email, setEmail] = useState<string>('')
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
  const { toast } = useToast()

  const validateEmail = (email: string): boolean => {
    if (email.length <= 0) {
      return true
    }
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Test the email against the regular expression
    return emailRegex.test(email)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const { mutateAsync: addEmail } =
    trpc.newsLetter.newsLetterSignup.useMutation()

  const handleEmailBlur = () => {
    const isValid = validateEmail(email)
    setIsEmailValid(isValid)

    if (!isValid) {
      setEmailErrorMessage('Please enter a valid email address.')
    } else {
      setEmailErrorMessage('')
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (isEmailValid) {
      try {
        await addEmail(email)
        toast({
          description: 'Email added successfully',
          variant: 'success',
        })
        setEmail('')
      } catch (error: any) {
        console.log('error in adding news letter email', error.message)
        toast({
          description: 'Something went wrong',
          variant: 'destructive',
        })
      }
    }
    // You can add further logic here for form submission if needed
  }

  return (
    <div className='bg-secondary w-full'>
      <div className='m-auto flex h-max w-[90%] max-w-[800px] flex-col items-center py-20 sm:flex-row sm:gap-x-[60px]'>
        <div className='flex flex-col text-white '>
          <h1 className='text-left text-3xl font-bold '>
            Map view and more coming soon
          </h1>
          <p className='mx-0 my-6 text-left text-[16px] font-[400] leading-6'>
            We’re always working to improve AquaData. Stay in the loop by
            signing up to receive our latest news and updates.
          </p>
          <p className='mx-0 text-left text-[16px] leading-6'>Email</p>
          <form onSubmit={handleSubmit}>
            <Input
              className={`mt-[4px] h-10 border ${
                isEmailValid ? 'border-[#e2e8f0]' : 'border-red-500'
              } bg-white text-[15px] text-black`}
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
            />
            {emailErrorMessage && (
              <p className='mt-1 text-sm text-red-500'>{emailErrorMessage}</p>
            )}
            <Button
              className='mt-6 h-9 w-max bg-[#cc9e38] text-[14px] font-semibold'
              type='submit'
            >
              Sign up for updates
            </Button>
          </form>
        </div>
        <Image
          src={worldPicture}
          alt='an image of the world map'
          className='mt-20 h-auto w-full sm:mt-0 sm:w-[344px]'
        />
      </div>
    </div>
  )
}

export default NewsLetterSignup
