import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Menu } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'

import { Button } from '../ui/button'
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet'

interface AuthButtonProps {
  onClick: () => void
  isAuthenticated: boolean
  className?: string
}

const AuthButton: React.FC<AuthButtonProps> = ({
  onClick,
  isAuthenticated,
  className,
}) => {
  return isAuthenticated ? (
    <Button
      onClick={onClick}
      variant='outline'
      className={`h-[36px] ${className}`}
    >
      Logout
    </Button>
  ) : (
    <Link href='/signin'>
      <Button variant='outline' className={`h-[36px] ${className}`}>
        Login
      </Button>
    </Link>
  )
}

const Navbar = () => {
  const session = useSession()

  const isAuthenticated = session && session.status === 'authenticated'

  return (
    <nav className='fixed z-10 h-14 w-full bg-white'>
      <div className=' outer-container-sm md:outer-container-md lg:outer-container flex h-full flex-row items-center justify-between bg-white'>
        <Link href='/'>
          <Image
            src='/svg/aquadatalogo.svg'
            alt='logo'
            height='24'
            width='113'
          />
        </Link>
        <div className='hidden h-[36px] flex-row gap-x-3  sm:flex'>
          <Link href='/search'>
            <Button variant='ghost' className='h-[36px]'>
              Search stories
            </Button>
          </Link>
          {isAuthenticated && (
            <Link href='/storylist'>
              <Button variant='ghost' className='h-[36px]'>
                All Stories
              </Button>
            </Link>
          )}
          <AuthButton onClick={signOut} isAuthenticated={isAuthenticated} />
        </div>
        <div className='flex sm:hidden'>
          <Sheet>
            <SheetTrigger>
              <Menu className='text-primary' />
            </SheetTrigger>
            <SheetContent>
              <div className='flex flex-col gap-y-6'>
                <Link href='/search' className='text-primary text-center '>
                  Search stories
                </Link>
                <Link
                  href='mailto:infoaquadata@cgiar.org'
                  className='text-primary text-center '
                >
                  Get in touch
                </Link>
                <AuthButton
                  onClick={signOut}
                  isAuthenticated={isAuthenticated}
                  className='w-full'
                />
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
