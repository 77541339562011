import Image from 'next/image';
import Link from 'next/link';

const footerLinks: { label: string; href: string; target?: string }[] = [
  {
    label: 'Search stories',
    href: '/',
    target: '_blank',
  },
  {
    label: 'About',
    href: 'https://worldfishcenter.org/aquadata',
  },
  {
    label: 'Get in touch',
    href: 'mailto:infoaquadata@cgiar.org',
  },
]

const Footer = () => {
  return (
    <div className='mt-7 w-full pb-[70px]'>
      <div className=' outer-container-sm md:outer-container-md lg:outer-container flex flex-col items-center justify-center bg-white'>
        <div className='flex w-full flex-col gap-y-12 sm:flex-row sm:items-center sm:justify-between'>
          <div className='flex flex-col gap-y-6'>
            {footerLinks.map((item, index) => {
              return (
                <Link
                  href={item.href}
                  target={item.target}
                  className='text-secondary text-center text-[14px] font-semibold sm:text-start'
                  key={index}
                >
                  {item.label}
                </Link>
              )
            })}
          </div>
          <div className='flex flex-row justify-center gap-x-8 sm:place-self-start'>
            <Link
              href='https://www.cgiar.org/initiative/aquatic-foods/'
              target='_blank'
            >
              <Image
                src='/images/AquaticFoods.png'
                alt='Cgiar Aquatic Foods Logo'
                height={42}
                width={120}
              />
            </Link>
            <Link href='https://worldfishcenter.org/' target='_blank'>
              <Image
                src='/svg/WorldFishlogoNew.svg'
                className=''
                alt='World Fish Center Logo'
                height='38'
                width='84'
              />
            </Link>
          </div>
        </div>
        <div className='mt-11 flex flex-col items-center justify-center'>
          <div className='flex flex-row gap-x-2'>
            <Link
              href='https://www.facebook.com/worldfishcenter'
              target='_blank'
              className=' flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-black bg-opacity-5'
            >
              <Image
                src='/svg/facebook.svg'
                alt='facebook'
                width='20'
                height='20'
              />
            </Link>
            <Link
              href='https://twitter.com/WorldFishCenter'
              target='_blank'
              className=' flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-black bg-opacity-5'
            >
              <Image
                src='/svg/twitter.svg'
                alt='facebook'
                width='20'
                height='20'
              />
            </Link>
            <Link
              href='https://www.flickr.com/photos/theworldfishcenter'
              target='_blank'
              className=' flex h-10 w-10 cursor-pointer items-center justify-center rounded-md bg-black bg-opacity-5'
            >
              <Image
                src='/svg/flicker.svg'
                alt='facebook'
                width='20'
                height='20'
              />
            </Link>
          </div>
          <div className='text-primary mt-7 flex flex-row items-center justify-between gap-x-7'>
            <p className='text-[14px] font-semibold'>Terms</p>
            <p className='text-[10px]'>© 2024 Worldfish</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
