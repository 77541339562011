import type { GetServerSideProps } from 'next'
import { createServerSideHelpers } from '@trpc/react-query/server'

import { appRouter } from '@aqua/api'
import { getServerAuthSession } from '@aqua/auth'
import { db } from '@aqua/db'
import type {
  CategoryWithChildren,
  CategoryWithParentCategory,
  StoryWithKeyIndicatorsAndCategories,
} from '@aqua/db/types'

import AddStories from '~/components/home/AddStories'
import HeroSection from '~/components/home/HeroSection'
import LatestBlogSection from '~/components/home/LatestBlogSection'
import NewsLetterSignup from '~/components/home/NewsLetterSignup'
import Layout from './layout'

export default function HomePage(props: PageProps & { hasError: boolean }) {
  return (
    <>
      <Layout>
        <main className='w-full pt-14'>
          <HeroSection />
          {!props.hasError && <LatestBlogSection data={props} />}
          <NewsLetterSignup />
          <AddStories />
        </main>
      </Layout>
    </>
  )
}

export interface PageProps {
  categoryTree: CategoryWithChildren[]
  storiesData: StoryWithKeyIndicatorsAndCategories[]
  innermostParents: {
    storyId: number
    type: CategoryWithParentCategory | null
  }[]
}

export const getServerSideProps: GetServerSideProps<
  PageProps & { hasError: boolean }
> = async (context) => {
  const session = await getServerAuthSession({
    req: context.req,
    res: context.res,
  })
  const helpers = createServerSideHelpers({
    router: appRouter,
    ctx: {
      session,
      db,
    },
  })

  try {
    const stories = (await helpers.stories.latestStories.fetch()) as PageProps

    return {
      props: { ...stories, hasError: false }, // Add hasError: false here indicating success
    }
  } catch (error) {
    console.error('Failed to fetch stories:', error)

    // Return hasError: true to indicate failure
    return {
      props: {
        categoryTree: [],
        storiesData: [],
        innermostParents: [],
        hasError: true,
      },
    }
  }
}
