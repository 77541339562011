import React from 'react'
import Link from 'next/link'
import { Button } from '@/components/ui/button'

const AddStories = () => {
  return (
    <div className='bg-primary w-full text-white'>
      <div className='m-auto flex h-max w-[90%] max-w-[800px] flex-col items-center justify-center gap-6 px-0 py-20'>
        <h2 className='text-center text-3xl font-semibold sm:text-right'>
          Want to add your stories to Aquadata?
        </h2>
        <Link
          href='mailto:infoaquadata@cgiar.org'
          className='text-primary flex h-[35px] w-max items-center justify-center rounded-md bg-white px-4 py-2 text-sm font-semibold hover:bg-[#fffaf0]'
        >
          Get in touch
        </Link>
      </div>
    </div>
  )
}

export default AddStories
